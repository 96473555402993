// import React, { useEffect } from "react";
// import gsap from "gsap";
import React from "react";

import './contact.css';

function Contact() {

    return (
        <>
            Contact Page
        </>
    )
}

export default Contact;